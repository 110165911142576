.banner {
    /* background-color: rgb(93, 91, 91); */
    height: 60vh;
    margin: 5%;
    padding: 2%;
}

.tileSpecs {
    /* background-color: rgb(249, 249, 247); */
    margin: 2%;
    justify-content: center;
    text-align: center;
    height: 20vh;
    display: flex;
    flex-direction: column;
}

.textBlock {
    margin: 1% auto;
    font-size: larger;
    font-weight: 700;
    text-transform: uppercase;
}

.spinnerContainer {
	height: 100vh;
	/* display: grid; */
	place-items: center;
	margin: auto;
	padding: 0;
	background: transparent;
}