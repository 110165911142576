.sidebarScrollbarLight::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.sidebarScrollbarLight:hover::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  overflow-y: scroll;
}

.sidebarScrollbarLight:hover::-webkit-scrollbar-track {
  background: transparent;
}

.sidebarScrollbarLight::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.sidebarScrollbarLight:hover::-webkit-scrollbar-thumb {
  background-color: rgb(228, 225, 225);
  border-radius: 20px;
  border: 3px solid rgb(228, 225, 225);
}

.sidebarScrollbarDark::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.sidebarScrollbarDark:hover::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  overflow-y: scroll;
}

.sidebarScrollbarDark:hover::-webkit-scrollbar-track {
  background: transparent;
}

.sidebarScrollbarDark::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.sidebarScrollbarDark:hover::-webkit-scrollbar-thumb {
  background-color: rgb(156, 155, 155);
  border-radius: 20px;
  border: 3px solid rgb(156, 155, 155);
}

.menu-list .backgroundDark {
  background-color: transparent;
}

.menu-list .backgroundDark:hover {
  background-color: hsl(0, 1%, 39%);
  color: black;
}

.menu-list .backgroundLight {
  background-color: hsl(0, 0%, 96%);
}

.menu-list a:hover {
  /* background-color: rgb(190, 186, 186); */
  background-color: red;
}

.menu-list a.active {
  /* background-color: rgb(190, 186, 186); */
  background-color: red;
}

/* .menu-list aactive {
  background-color: red;
} */