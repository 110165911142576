.app {
	text-align: center;
}

.navigation-light {
	background: #fff;
	box-shadow: 0 2px 18px 0 rgb(129 162 182 / 20%);
}

.navigation-dark {
	background: #000;
	box-shadow: 0 2px 18px 0 rgba(249, 250, 250, 0.2);
}

.switch {
	position: relative;
	display: inline-block;
	width: 56px;
	height: 28px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  /* The slider */
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #2196F3;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }
