.spinnerContainer {
	text-align: center;
	/* padding-bottom: 100px; */
	padding-top: 25%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.spinner {
	height: 100px;
	width: 100px;
}
