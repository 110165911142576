.buttonDisabled {
	text-transform: inherit !important;
	border-radius: 6px;
	margin: 0.5rem;
	padding: 0.4rem;
	font-style: oblique;
	display: flex;
	justify-content: center;
	min-width: 30px;
}

.buttonDisabled:hover {
	cursor: not-allowed;
}

.buttonSubmit-light {
	text-transform: inherit !important;
	background-color: var(--button-submit-light) !important;
	border-radius: 6px;
	border: 1px solid var(--button-submit-light);
	margin: 0.5rem;
	padding: 0.4rem;
	font-style: oblique;
	display: flex;
	justify-content: center;
	min-width: 30px;
}

.buttonSubmit-light:hover {
	cursor: pointer;
	opacity: 0.8;
}

.buttonSubmit-dark {
	text-transform: inherit !important;
	background-color: var(--button-submit-dark) !important;
	border-radius: 6px;
	border: 1px solid var(--button-submit-dark);
	margin: 0.5rem;
	padding: 0.4rem;
	font-style: oblique;
	display: flex;
	justify-content: center;
	min-width: 30px;
}

.buttonSubmit-dark:hover {
	cursor: pointer;
	opacity: 0.8;
}

.buttonCancel-light {
	text-transform: inherit !important;
	background-color: var(--button-cancel-light) !important;
	border: 1px solid var(--button-cancel-light);
	border-radius: 6px;
	margin: 0.5rem;
	padding: 0.4rem;
	font-style: oblique;
	display: flex;
	justify-content: center;
	min-width: 30px;
}

.buttonCancel-light:hover {
	cursor: pointer;
	opacity: 0.8;
}

.buttonCancel-dark {
	text-transform: inherit !important;
	background-color: var(--button-cancel-dark) !important;
	border: 1px solid var(--button-cancel-dark);
	border-radius: 6px;
	margin: 0.5rem;
	padding: 0.4rem;
	font-style: oblique;
	display: flex;
	justify-content: center;
	min-width: 30px;
}

.buttonCancel-dark:hover {
	cursor: pointer;
	opacity: 0.8;
}

.buttonDelete-light {
	text-transform: inherit !important;
	background-color: var(--button-delete-light) !important;
	border: 1px solid var(--button-delete-light);
	border-radius: 6px;
	margin: 0.5rem;
	padding: 0.4rem;
	font-style: oblique;
	display: flex;
	justify-content: center;
	min-width: 30px;
	/* cursor: not-allowed; */
}

.buttonDelete-light:hover {
	cursor: pointer;
	opacity: 0.8;
}

.buttonDelete-dark {
	text-transform: inherit !important;
	background-color: var(--button-delete-dark) !important;
	border: 1px solid var(--button-delete-dark);
	border-radius: 6px;
	margin: 0.5rem;
	padding: 0.4rem;
	font-style: oblique;
	display: flex;
	justify-content: center;
	min-width: 30px;
	/* cursor: not-allowed; */
}

.buttonDelete-dark:hover {
	cursor: pointer;
	opacity: 0.8;
}

.icon {
	font-size: 10px;
}
