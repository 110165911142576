* {
	margin: 0;
}

body {
	background-color: #f8f8f8;
	--askmeall-color-light: #ff385c;
	--askmeall-color-dark: #ac0927;
	/* --button-submit-light: hsl(171, 100%, 41%);
	--button-submit-dark: hsl(171, 100%, 29%); */
	/* --button-submit: #0a8d48; */
	/* --button-cancel-light: hsl(204, 86%, 53%);
	--button-cancel-dark: hsl(204, 71%, 39%);
	--button-delete-light: hsl(348, 100%, 61%);
	--button-delete-dark: hsl(348, 86%, 43%); */
	/* overflow: hidden; */
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
